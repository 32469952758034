import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    margin: 0;
    border: 0;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
`;
